import produce from "immer";
import { handleActions } from "redux-actions";

import { PlatformState } from "../../interfaces";

import {
  START,
  FAIL,
  SUCCESS,
  FETCH_ITEMS,
  RESET,
  PLATFORM,
} from "../common";

const initialState: PlatformState = {
  items: [],

  loading: false,
  loaded: false,
  error: null,
};

export const platformReducer = handleActions<PlatformState, any>(
  {
    [PLATFORM + RESET]: (state) =>
      produce(state, (draft) => {
        draft = initialState;
      }),
    [PLATFORM + FETCH_ITEMS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [PLATFORM + FETCH_ITEMS + SUCCESS]: (state, { payload: { data } }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.items = [
          ...new Set(data.map((e: any) => e.Platform).filter(Boolean)).values(),
        ] as any;
        draft.items.sort((a, b) => a.localeCompare(b));
        draft.error = null;
      }),
    [PLATFORM + FETCH_ITEMS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
  },
  initialState
);
