import { toast } from "react-toastify";

import firestore from "src/apiService/firestore";
import { getUserId } from "src/config/storage";
import {
  userPlanSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import { getPoshmarkIntegrations } from "src/store/plaidIntegration/selector";
import { lastDownloadTypeSelector } from "src/store/uploads/selector";
import { requestNOUpdate } from "src/apiService/modules/numericOverview";
import { poshmarkIntegrationUpdate } from "src/apiService/modules/poshmark";
import { getExtensionId, MissingExtensionError } from "src/utils/extension";
import { getPoshmarkUser } from "src/utils/extension/poshmark";
import type { PoshmarkIntegration } from "src/interfaces/plaidIntegration.interface";
import type MonthYear from "src/interfaces/monthYear.interface";
import paralelize from "src/utils/paralelize";
import Cacher from "src/utils/Cacher";
import FirebaseBatcher from "src/utils/FirebaseBatcher";

import type { Dispatcher, SyncStatus, ErrorSyncStatusCode } from "../../types";
import { updateSyncStatus as _updateSyncStatus } from "../actions";
import {
  isUserAllowedToDirectImport,
  saveSyncDate,
  getLastSyncDate,
  canRunSync,
  setRunningInterval,
  clearIsRunning,
  setBeforeUnloadHandler,
  clearBeforeUnloadHandler,
  ReSyncInterval,
  RunningKeys,
  uploadFile,
  shouldRunDailySync,
} from "../utils";

import { mapSale, factorReturnedSale, mapInventory } from "./mappings";
import PoshmarkClient, { NotLoggedError } from "./PoshmarkClient";

function updateSyncStatus(status: SyncStatus) {
  return _updateSyncStatus({
    integration: "poshmark" as const,
    status,
  });
}

const ParalelizeJobs = 10;
const MinDownloadWaitTime = 10 * 60 * 1000; // 10 min

async function updateIntegration(integrationId: string) {
  try {
    const data = await getPoshmarkUser();

    if (data.jwt) {
      await poshmarkIntegrationUpdate({
        integrationId,
        jwt: data.jwt,
      });
    }
  } catch (err) {
    console.error("There was an error:", err);
  }
}

async function syncSale(
  uid: string,
  client: PoshmarkClient,
  item: any,
  file: string
) {
  const db = firestore();
  const transformed = mapSale(item);
  const transactionId = transformed[0].transaction_id;
  if (transactionId) {
    const snapshot = await db
      .collection("Sales")
      .where("user", "==", uid)
      .where("transaction_id", "==", transactionId)
      .get();

    if (!snapshot.empty) return;
  }

  for (const i of transformed) {
    const doc = db.collection("Sales").doc();
    const s = {
      ...i,
      id: doc.id,
      user: uid,
      unreviewed: true,
      file,
    };

    if (item?.details?.order?.display_status === "Order Cancelled") {
      const r = factorReturnedSale(s, item);
      const d = db.collection("Sales").doc();
      s.return_id = d.id;
      await d.set({
        ...r,
        id: d.id,
        user: uid,
        unreviewed: true,
        file,
      });
    }

    await doc.set(s);
  }
}

async function syncSales(
  client: PoshmarkClient,
  integration: PoshmarkIntegration,
  date: Date
): Promise<void> {
  const lastDate = getLastSyncDate(
    integration,
    "poshmarkLastSyncDateTransactions"
  );
  const poshmarkUserId = integration.uid;
  const uid = await getUserId();
  const timestamp = date.valueOf();
  const filename = `${uid}/poshmarkSales/${timestamp}_${integration.id}.json`;
  const allSales: any[] = [];

  for await (const { sales } of client.fetchSalesFull(poshmarkUserId, {
    boughtDate: {
      start: lastDate,
    },
  })) {
    allSales.push(...sales);
    for (const sale of sales) {
      sale.details = await client.fetchSaleDetail(sale.id);
      if (sale.details?.order?.line_items) {
        for (const lineItem of sale.details.order.line_items) {
          lineItem.product = await client.fetchInventoryItem(
            lineItem.product_id
          );
        }
      }

      await syncSale(uid, client, sale, filename);
    }
  }

  await uploadFile(filename, JSON.stringify(allSales));
}

function getCancelledThresholdDate(): string {
  const d = new Date();
  d.setMonth(d.getMonth() - 3);
  return d.toISOString().split("T")[0];
}

function findClosestAndRemove(sales, sale) {
  let index: number | undefined = undefined;
  let salePrice = undefined;
  for (let i = 0; i < sales.length; i++) {
    const current = sales[i];
    if (salePrice !== undefined) {
      const diff = Math.abs(salePrice - sale.sale_price);
      const cDiff = Math.abs(current.sale_price - sale.sale_price);
      if (cDiff > diff) continue;
    }

    index = i;
    salePrice = current.sale_price;
  }

  if (index !== undefined) return sales.splice(index, 1)[0];
}

async function syncReturns(
  client: PoshmarkClient,
  integration: PoshmarkIntegration,
  start: string
): Promise<void> {
  const uid = await getUserId();
  const db = firestore();
  const lastDate = getLastSyncDate(
    integration,
    "poshmarkLastSyncDateTransactions"
  );
  const poshmarkUserId = integration.uid;
  const cancelledThresholdDate = getCancelledThresholdDate();

  for await (const { sales: returns } of client.fetchSalesFull(poshmarkUserId, {
    status: ["cancelled"],
  })) {
    for (const ret of returns) {
      ret.details = await client.fetchSaleDetail(ret.id);

      if (ret.details?.order?.line_items) {
        for (const lineItem of ret.details.order.line_items) {
          lineItem.product = await client.fetchInventoryItem(
            lineItem.product_id
          );
        }
      }

      if (ret?.details?.order?.cancelled_on < lastDate) continue;
      if (ret?.details?.order?.display_status !== "Order Cancelled") continue;
      const transformed = mapSale(ret);
      const transactionId = transformed[0].transaction_id;
      if (!transactionId) continue;
      const snapshot = await db
        .collection("Sales")
        .where("user", "==", uid)
        .where("transaction_id", "==", transactionId)
        .get();
      const snapshotData = snapshot.docs.map((s) => s.data());
      if (snapshotData.some((s) => s.is_return)) continue;
      for (const i of transformed) {
        const doc = db.collection("Sales").doc();
        const s = findClosestAndRemove(snapshotData, i) || i;
        const r = factorReturnedSale(s, ret);
        await doc.set({
          ...r,
          id: doc.id,
          user: uid,
          unreviewed: true,
          sync_start: start,
        });

        if (s.id) {
          await db.collection("Sales").doc(s.id).update({
            in_process: false,
            return_id: doc.id,
          });
        }
      }
    }

    if (returns.some((s) => s.inventory_booked_at < cancelledThresholdDate))
      break;
  }
}

async function syncInProcess(client: PoshmarkClient, start: string) {
  const uid = await getUserId();
  const db = firestore();
  const snapshot = await db
    .collection("Sales")
    .where("user", "==", uid)
    .where("in_process", "==", true)
    .get();
  if (snapshot.empty) return;

  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (!data.transaction_id) continue;
    if (!data.sale_platform || data.sale_platform?.toLowerCase() !== "poshmark")
      continue;
    if (data.is_return) {
      // Returns shouldn't be marked as "in process"
      // Update that flag and ignore it
      console.warn(
        "poshmark sales daily sync found an in process return (in process)",
        {
          id: doc.id,
          data,
        }
      );

      await doc.ref.update({ in_process: false });
      continue;
    }

    try {
      const details = await client.fetchSaleDetail(data.transaction_id);
      const status = (details.order.display_status || "").trim().toLowerCase();
      const state = (details.order.state || "").trim().toLowerCase();
      if (state === "cancelled" || status === "order complete") {
        const update: { in_process: boolean; return_id?: string } = {
          in_process: false,
        };
        if (
          (details?.order?.state || "").trim().toLowerCase() === "cancelled"
        ) {
          const ret = db.collection("Sales").doc();
          update.return_id = ret.id;
          const r = factorReturnedSale(data, {
            details: details,
          });
          const s = {
            ...r,
            id: ret.id,
            user: uid,
            unreviewed: true,
            sync_start: start,
            original_id: data.id,
          };
          await ret.set(s);
        }
        await db.collection("Sales").doc(data.id).update(update);
      }
    } catch (error) {
      console.trace(
        error,
        `id: ${data?.id}`,
        `trasaction id: ${data?.transaction_id}`
      );
    }
  }
}

function getISODate(str) {
  try {
    const d = new Date(str);
    return d.toISOString();
  } catch (error) {
    console.warn("Couldn't convert str into date's ISO String", {
      error,
      msg: (error as Error).toString(),
      stack: (error as Error).stack,
      str,
    });
    return str;
  }
}

async function syncInventory(
  client: PoshmarkClient,
  integration: PoshmarkIntegration,
  date: Date
): Promise<void> {
  const db = firestore();
  const lastDate = getLastSyncDate(
    integration,
    "poshmarkLastSyncDateInventory"
  );
  const poshmarkUserResponse = await client.fetchUser(integration.uid);
  const uid = await getUserId();
  const poshmarkUsername = poshmarkUserResponse.data.username;
  const its = [] as ReturnType<typeof mapInventory>;
  for await (const { items, response } of client.fetchInventoryFull(
    poshmarkUsername,
    { sortBy: "added_desc" }
  )) {
    for (const poshmarkItem of items) {
      if (getISODate(poshmarkItem.first_published_at) < lastDate) continue;
      const mappedItems = mapInventory(poshmarkItem);
      for (const item of mappedItems) {
        its.push(item);
      }
    }

    if (response.data.some((i) => getISODate(i.first_published_at) < lastDate))
      break;
  }

  if (its.length) {
    const timestamp = date.valueOf();
    const filename = `${uid}/poshmarkInventory/${timestamp}_${integration.id}.json`;
    await uploadFile(filename, JSON.stringify(its));
    const doc = db.collection("Downloads").doc();
    await doc.set({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      filename,
      new: true,
      timestamp,
      type: "poshmarkInventory",
      user: uid,
      integrationId: integration.id,
      integrationName: poshmarkUsername,
    });
  }
}

function syncDaily(
  integrationId: string,
  inventory: boolean,
  dispatcher: Dispatcher
) {
  return async (dispatch, getState) => {
    const db = firestore();
    const start = new Date();
    const canRun = await canRunSync(integrationId, RunningKeys);

    if (!canRun) {
      console.warn("Sync is required, but cannot run it");
      // toast.warning("Please try again in a few minutes.");
      return;
    }

    // is syncing timer
    const interval = setRunningInterval(integrationId, RunningKeys);

    try {
      const getIntegration = () => {
        const integration = getPoshmarkIntegrations(getState()).find(
          ({ id }) => id === integrationId
        );

        if (!integration) throw Error(`Integration not found ${integrationId}`);

        return integration;
      };
      const client = new PoshmarkClient();
      const cookieUserId = await client.getUserId();

      if (cookieUserId !== getIntegration().uid) throw new NotLoggedError();

      // Sales
      dispatch(updateSyncStatus({ type: "syncing", step: "sales" }));
      await syncSales(client, getIntegration(), start);

      // Returns
      dispatch(updateSyncStatus({ type: "syncing", step: "returns" }));
      await syncReturns(client, getIntegration(), start.toISOString());

      await saveSyncDate(
        integrationId,
        "poshmarkLastSyncDateTransactions",
        start.toISOString()
      );

      // In Process Sales
      dispatch(updateSyncStatus({ type: "syncing", step: "in-process" }));
      await syncInProcess(client, start.toISOString());

      await requestNOUpdate();

      if (inventory) {
        dispatch(updateSyncStatus({ type: "syncing", step: "inventory" }));
        if (
          dispatcher !== "auto" ||
          (lastDownloadTypeSelector(getState(), "poshmarkInventory")
            ?.timestamp || 0) +
            MinDownloadWaitTime <
            Date.now()
        ) {
          await syncInventory(client, getIntegration(), start);
          await saveSyncDate(
            integrationId,
            "poshmarkLastSyncDateInventory",
            start.toISOString()
          );
        }
      }

      await db.collection("Plaid_Integrations").doc(integrationId).update({
        lastSync: start.toISOString(),
      });
      await updateIntegration(integrationId);
    } finally {
      clearInterval(interval);
      await clearIsRunning(integrationId, RunningKeys);
    }
  };
}

export function poshmarkSync(
  integration: PoshmarkIntegration,
  dispatcher: Dispatcher
) {
  return async (dispatch, getState) => {
    let extensionId;
    try {
      extensionId = getExtensionId();
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you don’t have the My Reseller Genie extension installed. Go to the “Integrations” tab and click the “Pull Data Now” button for “Poshmark”. Follow the prompt to download the extension.",
            dispatcher,
            code: "install-extension",
          })
        );
        return;
      }
      dispatch(
        updateSyncStatus({
          type: "error",
          message: (e as Error).message || (e as Error).toString(),
          dispatcher,
          code: "unknown",
        })
      );
    }

    if (extensionId) {
      const inventoryType = userGetInventoryTypeSelector(getState());
      const shouldDisableInventory = inventoryType === "cash";

      const inventory =
        integration.inventory !== false && !shouldDisableInventory;

      setBeforeUnloadHandler();

      try {
        await dispatch(syncDaily(integration.id, inventory, dispatcher));
        dispatch(updateSyncStatus(undefined));
      } catch (e) {
        console.error(e);
        let code: ErrorSyncStatusCode = "unknown";

        if (e instanceof NotLoggedError) code = "user-not-logged-in";

        dispatch(
          updateSyncStatus({
            type: "error",
            message: (e as Error).message || (e as Error).toString(),
            code,
            dispatcher,
          })
        );
      } finally {
        clearBeforeUnloadHandler();
      }
    }
  };
}

export function poshmarkSyncWithCheck(
  integrationId: string,
  noTimeCheck = false
) {
  return async (dispatch, getState) => {
    const state = getState();
    const plan = userPlanSelector(state);

    if (plan === "ultimate") {
      const integration = getPoshmarkIntegrations(state)?.find(
        ({ id }) => id === integrationId
      );

      if (
        integration &&
        integration.type === "poshmark" &&
        integration.sync &&
        !integration.error
      ) {
        const shouldRun =
          noTimeCheck ||
          (await shouldRunDailySync(integration.id, ReSyncInterval));

        if (shouldRun) {
          await dispatch(
            poshmarkSync(integration, noTimeCheck ? "manual" : "auto")
          );
        }
      }
    }
  };
}

export function poshmarkSyncInventory(integrationId: string) {
  return async (
    dispatch,
    getState
  ): Promise<{ error?: true; success?: true }> => {
    const db = firestore();
    const state = getState();

    if (!isUserAllowedToDirectImport(state, "poshmarkInventory"))
      return { error: true };
    const inventoryType = userGetInventoryTypeSelector(getState());
    if (inventoryType === "cash") return { error: true };

    const integration = getPoshmarkIntegrations(state)?.find(
      ({ id }) => id === integrationId
    );

    if (!integration || integration.type !== "poshmark" || integration.error)
      return { error: true };
    let extensionId;

    try {
      extensionId = getExtensionId();
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you don’t have the My Reseller Genie extension installed. Click profile icon > Web Extension and download the extension in order to connect your account.",
            dispatcher: "manual",
            code: "install-extension",
          })
        );
        return { error: true };
      }
      toast.error((e as Error).message);
      return { error: true };
    }

    if (!extensionId) return { error: true };

    const client = new PoshmarkClient();
    let cookieUserId;
    try {
      cookieUserId = await client.getUserId();
    } catch (e) {
      console.warn(e);
    }

    if (cookieUserId !== integration.uid) {
      dispatch(
        updateSyncStatus({
          type: "error",
          message: "You must be logged in to poshmark with the correct user",
          code: "user-not-logged-in",
          dispatcher: "manual",
        })
      );
      return { error: true };
    }

    const runningKeys = ["inventoryIsRunning" as const];
    const canRun = await canRunSync(integrationId, runningKeys);

    if (!canRun) {
      console.warn("Sync is required, but cannot run it");
      toast.warning("Please try again in a few minutes.");
      return { error: true };
    }

    // is syncing timer
    const interval = setRunningInterval(integrationId, runningKeys);
    setBeforeUnloadHandler();

    try {
      dispatch(
        updateSyncStatus({ type: "syncing", step: "direct-import-inventory" })
      );
      const uid = await getUserId();
      const timestamp = Date.now();
      const filename = `${uid}/poshmarkInventory/${timestamp}_${integrationId}.json`;
      const poshmarkUserResponse = await client.fetchUser(integration.uid);
      const poshmarkUsername = poshmarkUserResponse?.data?.username;
      if (!poshmarkUsername) throw new Error("Can't get poshmark user");
      const upload = db.collection("Uploads").doc();
      await upload.set({
        user: uid,
        type: "inventory",
        provider: "poshmark",
        filename,
        n: 0,
        timestamp,
        id: upload.id,
      });
      let docs = 0;

      const batch = new FirebaseBatcher();
      const cache = new Cacher<string, boolean>();
      const allItems: any[] = [];

      for await (const { items } of client.fetchInventoryFull(
        poshmarkUsername,
        { inventoryStatus: ["available"] }
      )) {
        allItems.push(...items);
        await paralelize(
          items,
          // eslint-disable-next-line no-loop-func
          async (poshmarkItem: (typeof items)[number]) => {
            if (poshmarkItem.inventory?.status !== "available") return;
            const mappedItems = mapInventory(poshmarkItem);
            const listingId = mappedItems[0].listing_id;
            if (listingId) {
              const isEmpty = await cache.run(listingId, async (listingId) => {
                const snapshot = await db
                  .collection("Inventory")
                  .where("user", "==", uid)
                  .where("listing_id", "==", listingId)
                  .get();
                return snapshot.empty;
              });

              if (!isEmpty) return;
            }
            for (const item of mappedItems) {
              const doc = db.collection("Inventory").doc();
              await batch.set(doc, {
                ...item,
                id: doc.id,
                user: uid,
                upload: upload.id,
              });
              docs++;
            }
          },
          ParalelizeJobs
        );
      }

      await batch.wait();
      await batch.commit();
      await uploadFile(filename, JSON.stringify(allItems));

      await upload.update({
        n: docs,
      });
      await requestNOUpdate();
    } catch (e) {
      console.error(e);
      toast.error(`Error: ${(e as Error).message}`);
      return { error: true };
    } finally {
      dispatch(updateSyncStatus(undefined));
      clearBeforeUnloadHandler();
      clearInterval(interval);
      await clearIsRunning(integrationId, runningKeys);
    }

    return { success: true };
  };
}

export function poshmarkSyncSales(
  integrationId: string,
  { start, end }: { start: MonthYear; end: MonthYear }
) {
  return async (
    dispatch,
    getState
  ): Promise<{ success?: boolean; error?: boolean }> => {
    const db = firestore();
    const state = getState();

    if (!isUserAllowedToDirectImport(state, "poshmarkSales"))
      return { error: true };
    const integration = getPoshmarkIntegrations(state)?.find(
      ({ id }) => id === integrationId
    );

    if (!integration || integration.type !== "poshmark" || integration.error)
      return { error: true };
    let extensionId;

    try {
      extensionId = getExtensionId();
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        dispatch(
          updateSyncStatus({
            type: "error",
            message:
              "Looks like you don’t have the My Reseller Genie extension installed. Click profile icon > Web Extension and download the extension in order to connect your account.",
            dispatcher: "manual",
            code: "install-extension",
          })
        );
        return { error: true };
      }
      toast.error((e as Error).message);
      return { error: true };
    }

    if (!extensionId) return { error: true };

    const client = new PoshmarkClient();
    let cookieUserId;
    try {
      cookieUserId = await client.getUserId();
    } catch (e) {
      console.warn(e);
    }

    if (cookieUserId !== integration.uid) {
      dispatch(
        updateSyncStatus({
          type: "error",
          message: "You must be logged in to poshmark with the correct user",
          code: "user-not-logged-in",
          dispatcher: "manual",
        })
      );
      return { error: true };
    }

    const runningKeys = ["salesIsRunning" as const];
    const canRun = await canRunSync(integrationId, runningKeys);

    if (!canRun) {
      console.warn("Sync is required, but cannot run it");
      toast.warning("Please try again in a few minutes.");
      return { error: true };
    }

    // is syncing timer
    const interval = setRunningInterval(integrationId, runningKeys);
    setBeforeUnloadHandler();

    try {
      dispatch(
        updateSyncStatus({
          type: "syncing",
          step: "direct-import-sales",
        })
      );
      // Sync
      const uid = await getUserId();
      const poshmarkUserResponse = await client.fetchUser(integration.uid);
      const poshmarkUserId = poshmarkUserResponse?.data?.id;
      if (!poshmarkUserId) throw new Error("Can't get poshmark user");
      const timestamp = Date.now();
      const filename = `${uid}/poshmarkSales/${timestamp}_${integrationId}.json`;
      const upload = db.collection("Uploads").doc();
      await upload.set({
        user: uid,
        type: "sales",
        provider: "poshmark",
        filename,
        n: 0,
        timestamp,
        id: upload.id,
      });
      const uploadReturns = db.collection("Uploads").doc();
      await uploadReturns.set({
        user: uid,
        type: "returns",
        provider: "poshmark",
        filename,
        n: 0,
        timestamp,
        id: uploadReturns.id,
      });
      let docs = 0;
      let returns = 0;

      const startDate = new Date();
      startDate.setFullYear(start.year);
      startDate.setMonth(start.month - 1);
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date();
      endDate.setFullYear(end.year);
      endDate.setMonth(end.month);
      endDate.setDate(0);
      endDate.setHours(23, 59, 59, 999);

      const params = {
        boughtDate: {
          start: startDate.toISOString(),
          end: endDate.toISOString(),
        },
      };

      const batch = new FirebaseBatcher();
      const cache = new Cacher<string, boolean>();
      const allSales: any[] = [];

      for await (const { sales } of client.fetchSalesFull(
        poshmarkUserId,
        params
      )) {
        allSales.push(...sales);
        await paralelize(
          sales,
          // eslint-disable-next-line no-loop-func
          async (sale: (typeof sales)[number]) => {
            sale.details = await client.fetchSaleDetail(sale.id);
            if (sale.details?.order?.line_items) {
              for (const lineItem of sale.details.order.line_items) {
                lineItem.product = await client.fetchInventoryItem(
                  lineItem.product_id
                );
              }
            }

            const transformed = mapSale(sale);
            const transactionId = transformed[0].transaction_id;
            if (transactionId) {
              const isEmpty = await cache.run(
                transactionId,
                async (transactionId: string) => {
                  const snapshot = await db
                    .collection("Sales")
                    .where("user", "==", uid)
                    .where("transaction_id", "==", transactionId)
                    .get();
                  return snapshot.empty;
                }
              );

              if (!isEmpty) return;
            }

            for (const i of transformed) {
              const doc = db.collection("Sales").doc();
              const s = {
                ...i,
                id: doc.id,
                user: uid,
                upload: upload.id,
              };

              if (sale?.details?.order?.display_status === "Order Cancelled") {
                const r = factorReturnedSale(s, sale);
                const d = db.collection("Sales").doc();
                s.return_id = d.id;
                await batch.set(d, {
                  ...r,
                  id: d.id,
                  user: uid,
                  upload: uploadReturns.id,
                });
                returns++;
              }

              await batch.set(doc, s);
              docs++;
            }
          },
          ParalelizeJobs
        );
      }

      await batch.wait();
      await batch.commit();
      await uploadFile(filename, JSON.stringify(allSales));

      await requestNOUpdate();
      await upload.update({
        n: docs,
      });

      if (returns)
        await uploadReturns.update({
          n: returns,
        });
    } catch (e) {
      console.error(e);
      toast.error(`Error: ${(e as Error).message}`);
      return { error: true };
    } finally {
      dispatch(updateSyncStatus(undefined));
      clearBeforeUnloadHandler();
      clearInterval(interval);
      await clearIsRunning(integrationId, runningKeys);
    }

    return { success: true };
  };
}
