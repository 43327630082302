import type {
  ActiveDialog,
  AddInventoryProps,
} from "src/interfaces/adminHtmlState.interface";
import { userDontShowDialogsSelector } from "src/store/system/selector";

import { activeDialogSelector } from "./selector";

export const TOGGLE_MOBILE_OPEN = "TOGGLE_MOBILE_OPEN";
export const SET_ACTIVE_DIALOG = "SET_ACTIVE_DIALOG";
export const SET_WALKTHROUGH_ID = "SET_WALKTHROUGH_ID";

interface ToggleMobileOpenAction {
  type: typeof TOGGLE_MOBILE_OPEN;
}

interface SetActiveDialogActionGeneric {
  type: typeof SET_ACTIVE_DIALOG;
  activeDialog: ActiveDialog;
}

interface SetActiveDialogActionAddInventory {
  type: typeof SET_ACTIVE_DIALOG;
  activeDialog: "add_inventory";
  activeDialogProps: AddInventoryProps;
}

type SetActiveDialogAction =
  | SetActiveDialogActionGeneric
  | SetActiveDialogActionAddInventory;

interface SetWalkthroughIdAction {
  type: typeof SET_WALKTHROUGH_ID;
  walkthroughId: string;
}

export type AdminHtmlActionTypes =
  | ToggleMobileOpenAction
  | SetActiveDialogAction
  | SetWalkthroughIdAction;

export const toggleMobileOpen = (): AdminHtmlActionTypes => ({
  type: TOGGLE_MOBILE_OPEN,
});

export function setActiveDialog(
  activeDialog: ActiveDialog
): AdminHtmlActionTypes;
export function setActiveDialog(
  activeDialog: "add_inventory",
  activeDialogProps: AddInventoryProps
): AdminHtmlActionTypes;
export function setActiveDialog(
  activeDialog,
  activeDialogProps?
): AdminHtmlActionTypes {
  return {
    type: SET_ACTIVE_DIALOG,
    activeDialog,
    activeDialogProps,
  };
}

export function setActiveDialogIfCanBeShown(
  activeDialog: ActiveDialog,
  preventDialogFromOpeningIfADialogIsAlreadyOpened = false
) {
  return (dispatch, getState): boolean => {
    const state = getState();
    const userDontShowDialogs = userDontShowDialogsSelector(state);
    if (
      userDontShowDialogs &&
      userDontShowDialogs.includes(activeDialog as any)
    )
      return false;
    if (
      preventDialogFromOpeningIfADialogIsAlreadyOpened &&
      activeDialogSelector(state)
    )
      return false;
    dispatch(setActiveDialog(activeDialog));
    return true;
  };
}

export const setWalkthroughId = (
  walkthroughId: string
): AdminHtmlActionTypes => ({
  type: SET_WALKTHROUGH_ID,
  walkthroughId,
});
