import React, { useState, forwardRef, useRef, useEffect } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import GetAppIcon from "@material-ui/icons/GetApp";

import { colors } from "src/theme";
import { formatDollars } from "src/utils";

import { StatementDrillSalesTable } from "./StatementDrill";
import renderReport from "./renderReport";
import { SalesTaxReport as SalesTaxReportType } from "./report";

const StatementDrillDownTooltipText = "Click to view details";

type StatementDrillTypeWithReport = { state: string } | null;

function getSortedKeys(obj: Record<string, any>): string[] {
  const keys = Object.keys(obj);
  keys.sort((a, b) => a.localeCompare(b));

  return keys;
}

const useHeaderStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    width: "100%",
    textAlign: "left",
    color: theme.palette.primary.main,
  },
  reportType: {
    marginRight: theme.spacing(2),
    "& button": {
      lineHeight: 1,
    },
  },
}));

interface SalesTaxReportHeaderProps {
  downloading?: boolean;
  onDownloadClick?: () => void;
  report: SalesTaxReportType;
}

function SalesTaxReportHeader({
  report,
  downloading,
  onDownloadClick,
}: SalesTaxReportHeaderProps) {
  const classes = useHeaderStyles();

  return (
    <Box display="flex" alignItems="center">
      <h2 className={classes.title}>{report.data.reportDetails.titleFull}</h2>
      <Box display="flex">
        {onDownloadClick && (
          <IconButton onClick={onDownloadClick} disabled={downloading}>
            {downloading ? <CircularProgress size={24} /> : <GetAppIcon />}
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

const useBodyStyles = makeStyles((theme) => ({
  table: {
    margin: theme.spacing(3, "auto", 4),
    maxWidth: 480,
    width: "100%",
    borderSpacing: 0,
    borderCollapse: "collapse",
    "& td:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      paddingRight: theme.spacing(2),
    },
    "& td:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    "& td": {
      borderRadius: ({ isCompare }: { isCompare: boolean }) =>
        isCompare ? theme.shape.borderRadius : 0,
    },
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  bold: {
    fontWeight: 500,
  },
  detail: {
    paddingLeft: theme.spacing(2),
  },
  divider: {
    borderBottom: "1px solid #404854",
  },
  blue: {
    color: colors.blueColor,
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  space: {
    padding: theme.spacing(0, 2),
  },
  pointer: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  highlightedRow: {
    "& td": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
  highlightedCell: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
}));

interface SalesTaxReportBodyProps {
  report: SalesTaxReportType;
  onStatementDrillChange: (st: StatementDrillTypeWithReport) => void;
  statementDrill: StatementDrillTypeWithReport;
}

const SalesTaxReportBody = forwardRef(
  (
    { report, onStatementDrillChange, statementDrill }: SalesTaxReportBodyProps,
    ref
  ) => {
    const classes = useBodyStyles({ isCompare: false });

    return (
      <Box className={classes.table} {...{ ref }} component="table">
        <thead>
          <tr>
            <th className={clsx(classes.bold, classes.left)}>Sale State</th>
            <th className={clsx(classes.bold, classes.right)}>Liable to Pay</th>
            <th className={clsx(classes.bold, classes.right)}>
              Not Liable to Pay
            </th>
            <th className={clsx(classes.bold, classes.right)}>Total</th>
          </tr>
        </thead>
        <tbody>
          {getSortedKeys(report.data.taxByState).map((state) => {
            const value = report.data.taxByState[state];
            const selectedRow = statementDrill?.state === state;
            return (
              <Tooltip
                key={state}
                title={StatementDrillDownTooltipText}
                placement="top"
              >
                <tr
                  className={clsx(
                    classes.pointer,
                    selectedRow && classes.highlightedRow
                  )}
                  onClick={(e) => {
                    onStatementDrillChange(
                      selectedRow
                        ? null
                        : {
                            state,
                          }
                    );
                  }}
                >
                  <td className={classes.detail}>{state}</td>
                  <td className={classes.right}>
                    {formatDollars(value.liableToPay)}
                  </td>
                  <td className={classes.right}>
                    {formatDollars(value.nonLiableToPay)}
                  </td>
                  <td className={classes.right}>
                    {formatDollars(value.liableToPay + value.nonLiableToPay)}
                  </td>
                </tr>
              </Tooltip>
            );
          })}
        </tbody>
      </Box>
    );
  }
);

export interface SalesTaxReportProps {
  report: SalesTaxReportType;
  onReloadReport: () => void;
}

function StatementDrill({ type, salesByState, onReload }) {
  if (!type) return null;

  return (
    <Box mt={2}>
      <h2 className="text-blue">Sales by State - {type.state}</h2>
      <Box height="75vh">
        <StatementDrillSalesTable
          rows={salesByState[type.state] || []}
          onReload={onReload}
        />
      </Box>
    </Box>
  );
}

function SalesTaxReport({ report, onReloadReport }: SalesTaxReportProps) {
  const [downloading, setDownloading] = useState(false);
  const [statementDrill, setStatementDrill] =
    useState<StatementDrillTypeWithReport>(null);
  const bodyRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setStatementDrill(null);
  }, [report.data.reportDetails.titleFull]);

  const handleDownloadReport = async () => {
    if (!bodyRef?.current) return;
    const div = document.createElement("div");
    div.innerHTML = renderReport(
      report.data.reportDetails.titleFull,
      bodyRef.current.outerHTML,
      true
    );
    for (const ele of div.querySelectorAll("button, a")) {
      if (ele.parentNode) ele.parentNode.removeChild(ele);
    }
    setDownloading(true);
    const html2pdf = await import("html2pdf.js");
    await html2pdf
      .default()
      .set({
        filename: report.data.reportDetails.filename,
      })
      .from(div)
      .save();
    setDownloading(false);
  };

  return (
    <Paper className="mt-5 p-5 h-auto">
      <SalesTaxReportHeader
        report={report}
        downloading={downloading}
        onDownloadClick={() => handleDownloadReport()}
      />
      <SalesTaxReportBody
        ref={bodyRef}
        report={report}
        statementDrill={statementDrill}
        onStatementDrillChange={(st) => {
          setStatementDrill(st);
        }}
      />
      <StatementDrill
        type={statementDrill}
        salesByState={report.data.salesByState}
        onReload={() => onReloadReport()}
      />
    </Paper>
  );
}

export default SalesTaxReport;
