import React, { useMemo, useState, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  type GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@material-ui/x-grid";
import XGrid from "src/components/CSVFormattedXGrid";
import Box from "@material-ui/core/Box";

import { formatCurrency } from "src/utils";
import {
  renderHeaderLabel,
  ConfirmReturnDetailsDialog,
} from "src/pages/SalesPage/TableContent";
import { valueGetterWithDefault } from "src/utils/valueGetter";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import { EditDialog as SaleEditDialog } from "src/pages/SalesPage/EditDialog";
import { EditDialog as TransactionEditDialog } from "src/pages/TransactionPage/EditDialog";
import ConfirmDialog from "src/components/ConfirmDialog";
import { salesSelector, getSaleById } from "src/store/sale/selector";
import {
  updateItem as updateSale,
  updateBundledItems as updateBundleSales,
  deleteBundledItems as deleteBundledSales,
  moveBundledItems as moveBundledSales,
  moveItem as moveSale,
  recordReturn,
} from "src/store/sale/actions";
import { getTransactions } from "src/store/transaction/selector";
import { deleteReturn, deleteManySales } from "src/apiService/modules/sales";
import { deleteTransaction } from "src/apiService/modules/transactions";
import type { Sale } from "src/interfaces/sale.interface";
import type { Transaction } from "src/interfaces/transaction.interface";

import {
  TransactionFees,
  CostOfSoldInventory,
  ShippingCost,
} from "./constants";

import { ProfitLossReportData } from "./report";

export interface StatementDrillSalesType {
  type: "sales";
  platform: string;
}

export interface StatementDrillExpenseType {
  type: "expense";
  name: string;
}

export interface StatementDrillTransactionType {
  type: "transaction";
  name: string;
}

export type StatementDrillType =
  | StatementDrillSalesType
  | StatementDrillExpenseType
  | StatementDrillTransactionType
  | null;

export interface StatementDrillProps {
  data: ProfitLossReportData;
  type?: StatementDrillType;
  onReload?: () => void;
}

const ColumnsToHighlighByExpense = {
  [TransactionFees]: ["transaction_fees", "extra_transaction_fees"],
  [CostOfSoldInventory]: ["purchase_price"],
  [ShippingCost]: ["shipping_cost", "extra_shipping_cost"],
};

const SalesColumns: GridColumns = [
  {
    field: "item_title",
    headerName: "Item Title",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sub_category",
    headerName: "Sub Category",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "purchase_date",
    headerName: "Purchase Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "list_date",
    headerName: "List Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "return_date",
    headerName: "Return Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_date",
    headerName: "Sale Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "days_on_platform",
    headerName: "Days On Platform",
    type: "number",
    width: 220,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_price",
    headerName: "Sale Price",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "purchase_price",
    headerName: "Purchase Price",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "shipping_cost",
    headerName: "Shipping Costs",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "extra_shipping_cost",
    headerName: "Return Shipping Cost",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "extra_transaction_fees",
    headerName: "Return Transaction Fees",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "shipping_cost_analytics",
    headerName: "Shipping Costs (From Expense Detail)",
    type: "number",
    width: 285,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "transaction_fees",
    headerName: "Transaction Fees",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "other_fees",
    headerName: "Other Costs (From Expense Detail)",
    type: "number",
    width: 285,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "gross_profit",
    headerName: "Gross profit",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_state",
    headerName: "Sale State",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sales_tax",
    headerName: "Sales Tax",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "liable_to_pay",
    headerName: "Liable To Pay",
    width: 200,
    type: "boolean",
    renderHeader: renderHeaderLabel,
  },
  {
    field: "platforms_listed",
    headerName: "Platforms Listed",
    width: 300,
    renderHeader: renderHeaderLabel,
    valueFormatter: ({ value }) => {
      if (Array.isArray(value)) {
        return `${value.join(", ")}`;
      }
      return "";
    },
  },
  {
    field: "sale_platform",
    headerName: "Sale Platform",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
];
const SalesColumnsCashIgnored = [
  "purchase_date",
  "purchase_price",
  "platforms_listed",
];
const SalesColumnsCash = SalesColumns.filter(
  ({ field }) => !SalesColumnsCashIgnored.includes(field)
);

const TransactionsColumns: GridColumns = [
  {
    field: "account",
    headerName: "General Ledger Account",
    width: 250,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "name",
    headerName: "Charge Description",
    width: 200,
    type: "string",
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 200,
    type: "number",
    valueFormatter: formatCurrency,
  },
  { field: "date", headerName: "Date", width: 200, type: "date" },
  {
    field: "description",
    headerName: "Description",
    width: 250,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "integration_name",
    headerName: "Bank Account",
    width: 200,
    valueGetter: valueGetterWithDefault(""),
  },
];

function StatementGridTableToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function EditableTransactionsXGrid({
  rows,
  onReload,
}: {
  rows: Transaction[];
  onReload: StatementDrillProps["onReload"];
}) {
  const [transaction, setTransaction] = useState<Transaction | undefined>(
    undefined
  );
  return (
    <>
      <StatementDrillTransactionTableEditDialog
        transaction={transaction}
        onClose={(reload?: boolean) => {
          if (reload && onReload) onReload();
          setTransaction(undefined);
        }}
      />
      <XGrid
        rows={rows}
        columns={TransactionsColumns}
        pagination
        disableSelectionOnClick
        components={{
          Toolbar: StatementGridTableToolbar,
        }}
        onCellDoubleClick={(params) =>
          setTransaction(params.row as Transaction)
        }
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  highlightedHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  highlightedCell: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

function ExpenseStatementDrill({
  data,
  name,
  onReload,
}: {
  data: ProfitLossReportData;
  name: string;
  onReload: StatementDrillProps["onReload"];
}) {
  const [editOpen, setEditOpen] = useState<
    { type: "sale"; sale: Sale } | undefined
  >(undefined);
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  const classes = useStyles();
  const rowsSales = data.salesListByExpense?.[name];
  const rowsTransactions = data.transactionsListByExpense?.[name];
  const salesColumns = useMemo(() => {
    const highlight = ColumnsToHighlighByExpense[name];
    if (!highlight) return SalesColumns;
    return (inventoryTypeIsCash ? SalesColumnsCash : SalesColumns).map((c) => {
      if (highlight.includes(c.field)) {
        c = {
          ...c,
          headerClassName: classes.highlightedHeader,
          cellClassName: classes.highlightedCell,
        };
      }

      return c;
    });
  }, [
    name,
    classes.highlightedHeader,
    classes.highlightedCell,
    inventoryTypeIsCash,
  ]);

  return (
    <Box mt={2}>
      <h2 className="text-blue">Cost of Goods Sold - {name}</h2>
      <Box height="75vh">
        {!!rowsSales && (
          <Box height={rowsTransactions ? "50%" : "100%"}>
            <StatementDrillSalesTableEditDialog
              sale={editOpen?.type === "sale" ? editOpen.sale : undefined}
              onSaleChange={(sale: Sale) =>
                setEditOpen({
                  type: "sale",
                  sale,
                })
              }
              onClose={(reload?: boolean) => {
                if (reload && onReload) onReload();
                setEditOpen(undefined);
              }}
            />
            <XGrid
              rows={rowsSales}
              columns={salesColumns}
              pagination
              disableSelectionOnClick
              components={{
                Toolbar: StatementGridTableToolbar,
              }}
              onCellDoubleClick={(params) =>
                setEditOpen({
                  type: "sale",
                  sale: params.row as Sale,
                })
              }
            />
          </Box>
        )}
        {!!rowsTransactions && (
          <Box height={rowsSales ? "50%" : "100%"} mt={rowsSales ? 1 : 0}>
            <EditableTransactionsXGrid
              rows={rowsTransactions}
              onReload={onReload}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

function StatementDrillSalesTableEditDialog({
  sale,
  onSaleChange,
  onClose,
}: {
  sale?: Sale;
  onSaleChange: (sale: Sale) => void;
  onClose: (reload?: boolean) => void;
}) {
  const store = useStore();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState<
    | { type: "sale"; id: string }
    | { type: "return"; id: string }
    | { type: "bundle"; bundle: any }
    | { type: "return-details"; id: string }
    | undefined
  >(undefined);
  const { loading } = useSelector(salesSelector);
  const hasChangedRef = useRef(false);
  return (
    <>
      <ConfirmReturnDetailsDialog
        selectedUnreviewedReturnId={
          confirmOpen?.type === "return-details" ? confirmOpen.id : undefined
        }
        onClose={() => setConfirmOpen(undefined)}
      />
      <ConfirmDialog
        open={
          confirmOpen?.type === "sale" ||
          confirmOpen?.type === "return" ||
          confirmOpen?.type === "bundle"
        }
        title="Confirm"
        text={
          confirmOpen?.type === "sale"
            ? "Are you sure you want to delete the sale?"
            : confirmOpen?.type === "return"
            ? "Are you sure you want to delete the return?"
            : confirmOpen?.type === "bundle"
            ? "Are you sure you want to delete the bundled sales?"
            : undefined
        }
        onCancel={() => setConfirmOpen(undefined)}
        onConfirm={async () => {
          if (!confirmOpen) return;
          if (confirmOpen.type === "sale")
            await deleteManySales([confirmOpen.id]);
          else if (confirmOpen.type === "return")
            await deleteReturn(confirmOpen.id);
          else if (confirmOpen.type === "bundle")
            dispatch(deleteBundledSales(confirmOpen.bundle));
          setConfirmOpen(undefined);
          onClose(true);
          hasChangedRef.current = false;
        }}
      />
      <SaleEditDialog
        data={sale}
        open={!!sale}
        loading={loading}
        onClose={() => {
          if (loading) return;
          onClose(hasChangedRef.current);
          hasChangedRef.current = false;
        }}
        onSave={(s) => {
          hasChangedRef.current = true;
          dispatch(updateSale(s));
        }}
        onSaveBundle={(sales) => {
          hasChangedRef.current = true;
          dispatch(updateBundleSales(sales));
        }}
        onMove={(sale) => {
          hasChangedRef.current = true;
          dispatch(moveSale(sale));
        }}
        onMoveBundle={(sales) => {
          hasChangedRef.current = true;
          dispatch(moveBundledSales(sales));
        }}
        onReturn={(returnRecord, sale, inventory) => {
          hasChangedRef.current = true;
          dispatch(recordReturn({ returnRecord, sale, inventory }));
        }}
        onDelete={() => {
          if (!sale) return;
          setConfirmOpen({ type: "sale", id: sale.id });
        }}
        onDeleteBundle={(bundle) => {
          setConfirmOpen({ type: "bundle", bundle });
        }}
        onDeleteReturn={(payload) => {
          if (!sale) return;
          setConfirmOpen({ type: "return", id: sale.id });
        }}
        editReturn={(sale, unreviewed) => {
          if (unreviewed) {
            hasChangedRef.current = true;
            setConfirmOpen({ type: "return-details", id: sale.return_id });
          } else {
            const refund = getSaleById(store.getState(), sale.return_id);
            if (refund) onSaleChange(refund);
          }
        }}
      />
    </>
  );
}

function StatementDrillTransactionTableEditDialog({
  transaction,
  onClose,
}: {
  transaction?: Transaction;
  onClose: (reload?: boolean) => void;
}) {
  const [confirmOpen, setConfirmOpen] = useState<string | undefined>(undefined);
  const { loading } = useSelector(getTransactions);
  return (
    <>
      <ConfirmDialog
        open={!!confirmOpen}
        title="Confirm"
        text="Are you sure you want to delete the transaction?"
        onCancel={() => setConfirmOpen(undefined)}
        onConfirm={async () => {
          if (!confirmOpen) return;
          await deleteTransaction(confirmOpen);
          setConfirmOpen(undefined);
          onClose(true);
        }}
      />
      <TransactionEditDialog
        data={transaction}
        open={!!transaction}
        onClose={(hasChanged) => {
          onClose(!!hasChanged);
        }}
        onDelete={() => {
          if (!transaction?.id) return;
          setConfirmOpen(transaction.id);
        }}
        loading={loading}
        noCopyExpenseButton
      />
    </>
  );
}

export function StatementDrillSalesTable({
  rows,
  onReload,
}: {
  rows: any[];
  onReload: StatementDrillProps["onReload"];
}) {
  const [editSale, setEditSale] = useState<Sale | undefined>(undefined);
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";

  return (
    <>
      <StatementDrillSalesTableEditDialog
        sale={editSale}
        onSaleChange={(s: Sale) => setEditSale(s)}
        onClose={(reload?: boolean) => {
          if (reload && onReload) onReload();
          setEditSale(undefined);
        }}
      />
      <XGrid
        rows={rows}
        columns={inventoryTypeIsCash ? SalesColumnsCash : SalesColumns}
        pagination
        disableSelectionOnClick
        components={{
          Toolbar: StatementGridTableToolbar,
        }}
        onCellDoubleClick={(params, ev) => setEditSale(params.row as Sale)}
      />
    </>
  );
}

function StatementDrill({ data, type, onReload }: StatementDrillProps) {
  if (!type) return null;

  if (type.type === "sales" && type.platform) {
    const rows = data.salesListByDepartment?.[type.platform] || [];
    return (
      <Box mt={2}>
        <h2 className="text-blue">Income - {type.platform}</h2>
        <Box height="75vh">
          <StatementDrillSalesTable rows={rows} onReload={onReload} />
        </Box>
      </Box>
    );
  }

  if (type.type === "expense" && type.name)
    return (
      <ExpenseStatementDrill data={data} name={type.name} onReload={onReload} />
    );

  if (type.type === "transaction" && type.name) {
    const rows = data.transactionsListByExpense?.[type.name] || [];
    return (
      <Box mt={2}>
        <h2 className="text-blue">Other Business Expenses - {type.name}</h2>
        <Box height="75vh">
          <EditableTransactionsXGrid rows={rows} onReload={onReload} />
        </Box>
      </Box>
    );
  }

  return null;
}

export default StatementDrill;
