import { isNil } from "lodash";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import cleanListWithExtraValue from "src/utils/cleanListWithExtraValue";

import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { toast } from "react-toastify";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InfoTooltip from "src/components/InfoTooltip";
import { platformSelector } from "src/store/platform/selector";
import { itemOptionsSelector } from "src/store/itemOptions/selector";
import { inventoriesSelector } from "src/store/inventory/selector";

import { fetchItemsIfNeeded as fetchPlatforms } from "src/store/platform/actions";
import { fetchItemsIfNeeded as fetchItemOptions } from "src/store/itemOptions/actions";
import {
  createItem as addInventory /*, fetchItems as fetchInventories*/,
} from "src/store/inventory/actions";

import { clearUpdateMethod } from "src/store/common";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { activeDialogPropsSelector } from "src/store/adminHtml/selector";

import { Inventory, InventoryFormError } from "src/interfaces";
import { METHOD_TYPE } from "src/enums";
import { userPlatformsSelector } from "src/store/system/selector";

import AddEditAnalyticsGroupingsDialog from "src/pages/RootDialogs/ProfileSettings/AnalyticsGroupings/AddEditAnalyticsGroupingsDialog";
import { AnalyticsGroupingItem } from "src/interfaces/systemState.interface";
import BrandField from "src/components/BrandField";
import VendorField from "src/components/VendorField";
import {
  departmentsSelector,
  categoriesSelector,
  subCategoriesSelector,
} from "src/store/itemOptions/selector";

import NumberField from "src/components/NumberField";
import LoadableButton from "src/components/LoadableButton";
import Uploader from "src/components/Uploader";
import GoldIconButton from "src/components/GoldIconButton";

import WalkThrough from "./WalkThrough";

export interface AddInventoryDialogProps {
  open: boolean;
}

const initFormValues: Inventory = {
  id: "",
  brand: "",
  category: "",
  department: "",
  item_title: "",
  location: "",
  notes: "",
  platforms_listed: [],
  sku: "",
  sub_category: "",
  purchase_price: "" as unknown as number,
  purchase_date: new Date(),
  list_date: undefined,
  quantity: 1,
};

function AddInventoryDialogContent({ onClose }) {
  const activeDialogProps = useSelector(activeDialogPropsSelector);
  const [open, setOpen] = useState(false);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const shouldCloseRef = useRef(true);
  const dispatch = useDispatch();
  const [openAddAnalyticsGrouping, setOpenAddAnalyticsGrouping] =
    useState<AnalyticsGroupingItem>();

  const userPlatforms = useSelector(userPlatformsSelector);
  const { items: platforms, loading: platformsLoading } =
    useSelector(platformSelector);
  const { loading: itemOptionsLoading } = useSelector(itemOptionsSelector);
  const {
    loading,
    loaded,
    method,
    error: apiError,
  } = useSelector(inventoriesSelector);

  const initialValues = activeDialogProps || initFormValues;
  const [values, setValues] =
    React.useState<Omit<Inventory, "id">>(initialValues);
  const [errors, setErrors] = React.useState<InventoryFormError>({});

  const initialDepartments = useSelector(departmentsSelector);
  const department = values.department;
  const departments = useMemo(
    () => cleanListWithExtraValue(initialDepartments, department),
    [initialDepartments, department]
  );
  const initialCategories = useSelector((state: any) =>
    categoriesSelector(state, values)
  );
  const category = values.category;
  const categories = useMemo(
    () => cleanListWithExtraValue(initialCategories, category),
    [initialCategories, category]
  );
  const initialSubCategories = useSelector((state: any) =>
    subCategoriesSelector(state, values)
  );
  const subCategory = values.sub_category;
  const subCategories = useMemo(
    () => cleanListWithExtraValue(initialSubCategories, subCategory),
    [initialSubCategories, subCategory]
  );

  useEffect(() => {
    dispatch(fetchPlatforms());
    dispatch(fetchItemOptions());
  }, [dispatch]);

  const handleSave = (shouldClose = true) => {
    shouldCloseRef.current = shouldClose;
    if (validateForm()) {
      dispatch(addInventory(values));
    } else {
      toast.error("Some required information is missing or incomplete");
    }
  };

  const handleChange = (prop: keyof Inventory) => (event, t?: any) => {
    let value = event.target.value;
    if ("department" === prop) {
      value = departments[Number(value)];
    } else if ("category" === prop) {
      value = categories[Number(value)];
    } else if ("sub_category" === prop) {
      value = subCategories[Number(value)];
    } else if ("platforms_listed" === prop) {
      value = t;
    } else if ("purchase_price" === prop) {
      value = isNil(value) || value === "" ? null : +value;
    }
    setValues({ ...values, [prop]: value });
  };

  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const validateForm = () => {
    let hasError = false;
    /* rules */
    let newErrors: InventoryFormError = { ...errors };
    if (values.item_title === undefined || values.item_title.trim() === "") {
      hasError = true;
      newErrors.item_title = "Required field";
    } else {
      newErrors.item_title = undefined;
    }
    if (!values.quantity || values.quantity * 1 < 1) {
      hasError = true;
      newErrors.quantity = "Must be greater than 0";
    } else {
      newErrors.quantity = undefined;
    }
    if (!isValidDate(values.purchase_date)) {
      hasError = true;
      newErrors.purchase_date = "Invalid date format";
    } else {
      newErrors.purchase_date = undefined;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const renderOption = (option, { inputValue }) => {
    const matches = match(option, inputValue);
    const parts = parse(option, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (loaded && !apiError && method === METHOD_TYPE.CREATE) {
      dispatch(clearUpdateMethod());
      if (shouldCloseRef.current) {
        onCloseRef.current();
      } else {
        setValues(initFormValues);
      }
    }
  }, [loaded, apiError, method, dispatch]);

  return (
    <>
      <WalkThrough open={open} onClose={() => setOpen(false)} />
      <AddEditAnalyticsGroupingsDialog
        open={!!openAddAnalyticsGrouping}
        initialValue={openAddAnalyticsGrouping}
        onClose={(ag) => {
          setOpenAddAnalyticsGrouping(undefined);
          if (ag)
            setValues((v) => ({
              ...v,
              department: ag.department,
              category: ag.category,
              sub_category: ag.subcategory,
            }));
        }}
      />
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <DialogContentText component="div" className="relative" tabIndex={-1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className="flex justify-end">
                <TextField
                  error={errors.item_title ? true : false}
                  helperText={errors.item_title}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="Item Title"
                  value={values.item_title}
                  disabled={loading}
                  onChange={handleChange("item_title")}
                  required
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  id="add-inventory-dialog-item-title-tooltip"
                  text="Source where you purchased the item."
                  size={18}
                />
                <VendorField
                  margin="dense"
                  disabled={loading}
                  value={values.vendor}
                  onChange={(vendor) => {
                    if (vendor) setValues((values) => ({ ...values, vendor }));
                    else setValues(({ vendor, ...values }) => values);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="Departments help to organize your inventory, they are the largest grouping to set inventory/sales apart."
                  size={18}
                />
                <Autocomplete
                  disabled={itemOptionsLoading}
                  options={["Add new department", ...departments]}
                  value={values.department || null}
                  className="w-11/12"
                  autoHighlight
                  onChange={(_e, value) => {
                    if (value === "Add new department") {
                      setOpenAddAnalyticsGrouping({
                        department: "",
                        category: "",
                        subcategory: "",
                      });
                      setValues({
                        ...values,
                        department: "",
                        category: "",
                        sub_category: "",
                      });
                      return;
                    }
                    setValues({
                      ...values,
                      department: value || "",
                      category: "",
                      sub_category: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.department ? true : false}
                      helperText={errors.department}
                      margin="dense"
                      label="Department"
                      className="w-full"
                      variant="outlined"
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="Categories help to organize your inventory, they are the second largest grouping to set inventory/sales apart."
                  size={18}
                />
                <Autocomplete
                  disabled={itemOptionsLoading}
                  options={["Add new category", ...categories]}
                  value={values.category || null}
                  autoHighlight
                  className="w-11/12"
                  onChange={(_e, value) => {
                    if (value === "Add new category") {
                      setOpenAddAnalyticsGrouping({
                        department: values.department,
                        category: "",
                        subcategory: "",
                      });
                      setValues({ ...values, category: "", sub_category: "" });
                      return;
                    }
                    setValues({
                      ...values,
                      category: value || "",
                      sub_category: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.category ? true : false}
                      helperText={errors.category}
                      margin="dense"
                      label="Category"
                      className="w-full"
                      variant="outlined"
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="Sub-categories help to organize your inventory, they are the most specific grouping to set inventory/sales apart."
                  size={18}
                />
                <Autocomplete
                  disabled={itemOptionsLoading}
                  options={["Add new sub-category", ...subCategories]}
                  value={values.sub_category || null}
                  autoHighlight
                  className="w-11/12"
                  onChange={(_e, value) => {
                    if (value === "Add new sub-category") {
                      setOpenAddAnalyticsGrouping({
                        department: values.department,
                        category: values.category,
                        subcategory: "",
                      });
                      setValues({ ...values, sub_category: "" });
                      return;
                    }
                    setValues({ ...values, sub_category: value || "" });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.sub_category ? true : false}
                      helperText={errors.sub_category}
                      margin="dense"
                      label="Sub category"
                      className="w-full"
                      variant="outlined"
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-end">
                <BrandField
                  disabled={itemOptionsLoading}
                  value={values.brand || null}
                  className="w-11/12"
                  onChange={(e, value) => {
                    setValues((v) => ({ ...v, brand: value || "" }));
                  }}
                  TextFieldProps={{
                    error: errors.brand ? true : false,
                    helperText: errors.brand,
                    margin: "dense",
                    label: "Brand",
                    className: "w-full",
                    variant: "outlined",
                  }}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip text="Where you have stored the item." size={18} />
                <TextField
                  error={errors.location ? true : false}
                  helperText={errors.location}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="Location"
                  value={values.location}
                  disabled={loading}
                  onChange={handleChange("location")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-end">
                <TextField
                  error={errors.sku ? true : false}
                  helperText={errors.sku}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  label="SKU"
                  value={values.sku}
                  disabled={loading}
                  onChange={handleChange("sku")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="The amount you paid for the inventory."
                  size={18}
                />
                <NumberField
                  error={errors.purchase_price ? true : false}
                  helperText={errors.purchase_price}
                  className="w-full"
                  variant="outlined"
                  margin="dense"
                  label="Purchase Price"
                  value={values.purchase_price || ""}
                  disabled={loading}
                  onChange={handleChange("purchase_price")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-end">
                <Autocomplete
                  multiple
                  className="w-11/12"
                  disabled={platformsLoading}
                  options={[...platforms, ...(userPlatforms || [])]?.sort(
                    (a, b) => a?.toLowerCase()?.localeCompare(b?.toLowerCase())
                  )}
                  value={values.platforms_listed ? values.platforms_listed : []}
                  onChange={handleChange("platforms_listed")}
                  autoHighlight
                  renderOption={renderOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Platforms Listed"
                      variant="outlined"
                      className="w-full"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="Enter any other information that you need in here."
                  size={18}
                />
                <TextField
                  error={errors.notes ? true : false}
                  helperText={errors.notes}
                  className="w-full"
                  variant="outlined"
                  margin="dense"
                  label="Notes"
                  value={values.notes}
                  disabled={loading}
                  onChange={handleChange("notes")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="The date that you purchased this item."
                  size={18}
                />
                <KeyboardDatePicker
                  disableToolbar
                  className="w-11/12"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  margin="dense"
                  label="Purchase Date"
                  autoOk={true}
                  required
                  error={!!errors.purchase_date}
                  helperText={errors.purchase_date}
                  value={values.purchase_date || null}
                  disabled={loading}
                  onChange={(e: any, t: any) => {
                    setValues({ ...values, purchase_date: new Date(e) });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="The date that you listed this item for sale."
                  size={18}
                />
                <KeyboardDatePicker
                  disableToolbar
                  className="w-11/12"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  margin="dense"
                  label="List Date"
                  autoOk={true}
                  value={values.list_date || null}
                  disabled={loading}
                  onChange={(e: any, t: any) => {
                    if (e) {
                      setValues({ ...values, list_date: new Date(e) });
                    } else {
                      const { list_date, ...v } = values;
                      setValues({
                        ...v,
                        list_date: null,
                      });
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="flex justify-between">
                <InfoTooltip
                  text="The number of these items you’d like to create."
                  size={18}
                />
                <TextField
                  error={errors.quantity ? true : false}
                  helperText={errors.quantity}
                  className="w-11/12"
                  variant="outlined"
                  margin="dense"
                  required
                  label="Quantity"
                  type="number"
                  value={values.quantity}
                  disabled={loading}
                  onChange={handleChange("quantity")}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mt={-1.5}>
          <Tooltip title="Need help? Click here.">
            <GoldIconButton onClick={() => setOpen(true)} />
          </Tooltip>
        </Box>
        <Box flex={1} />
        <Uploader
          label="Bulk Upload"
          importerType="inventory"
          id="add-inventory-dialog-bulk-upload-button"
        />
        <LoadableButton
          loading={loading}
          color="primary"
          variant="contained"
          onClick={() => handleSave(false)}
        >
          Add and Next
        </LoadableButton>
        <LoadableButton
          onClick={() => handleSave(true)}
          color="primary"
          variant="contained"
          loading={loading}
        >
          Add
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function AddInventoryDialog({ open }: AddInventoryDialogProps) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setActiveDialog(""));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      <AddInventoryDialogContent onClose={handleClose} />
    </Dialog>
  );
}

export default AddInventoryDialog;
